import React from 'react';
import { Box, Typography } from '@mui/material';

const MediaCard = ({ title, description, videoUrl, onPlay, videoRef }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '15px',
        overflow: 'hidden',
        boxShadow: '0 8px 16px rgba(0, 255, 255, 0.4)',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        backgroundColor: '#0d0d0d',
        transform: 'scale(1)',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 12px 24px rgba(0, 255, 255, 0.7)',
          '& .glitch': {
            color: '#0ef',
            textShadow: '2px 2px 8px rgba(255, 0, 255, 0.7), -2px -2px 8px rgba(0, 255, 255, 0.7)',
          },
        },
      }}
    >
      {/* Glitchy border */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '15px',
          border: '2px solid',
          borderColor: 'rgba(0, 255, 255, 0.4)',
          boxShadow: '0 0 15px rgba(255, 0, 255, 0.6)',
          animation: 'glitchBorder 2s infinite',
          '@keyframes glitchBorder': {
            '0%': { transform: 'translate(0px, 0px)' },
            '20%': { transform: 'translate(-2px, 2px)' },
            '40%': { transform: 'translate(2px, -2px)' },
            '60%': { transform: 'translate(-2px, -2px)' },
            '80%': { transform: 'translate(2px, 2px)' },
            '100%': { transform: 'translate(0px, 0px)' },
          },
        }}
      ></Box>

      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          borderBottom: '4px solid #ff00ff',
        }}
      >
        <Box
          component="iframe"
          src={videoUrl}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          onPlay={onPlay}
          ref={videoRef}
          sx={{
            width: '100%',
            height: 300,
            border: 'none',
            filter: 'grayscale(50%) brightness(80%)',
            transition: 'filter 0.5s ease',
            '&:hover': {
              filter: 'grayscale(0%) brightness(100%)',
            },
          }}
        />
      </Box>

      {/* Title and Description with Glitch Effect */}
      <Box
        sx={{
          padding: '1em',
          color: '#00ffea',
          fontFamily: 'monospace',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Typography
          variant="h6"
          className="glitch"
          sx={{
            fontWeight: 'bold',
            color: '#0ef',
            textShadow: '2px 2px 8px rgba(255, 0, 255, 0.6), -2px -2px 8px rgba(0, 255, 255, 0.6)',
            animation: 'glitch 1.5s infinite',
            '@keyframes glitch': {
              '0%': { transform: 'translate(0, 0)' },
              '20%': { transform: 'translate(-2px, 2px)' },
              '40%': { transform: 'translate(2px, -2px)' },
              '60%': { transform: 'translate(-2px, -2px)' },
              '80%': { transform: 'translate(2px, 2px)' },
              '100%': { transform: 'translate(0, 0)' },
            },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: '#ccc',
            margin: '0.5em 0',
            fontFamily: 'monospace',
            textShadow: '1px 1px 4px rgba(0, 255, 255, 0.4)',
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default MediaCard;
