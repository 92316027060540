import React from "react";
import { Typography } from "@mui/material";
import YouTubeEmbed from "./YoutubeEmbed";

const PodcastComponent = () => {
     return (
        <div>
        <Typography 
          variant="h2" 
          align="center" 
          gutterBottom
          sx={{ mt: '40px', color: 'white' }}
        >
          The Fervent Four Podcast
        </Typography>
        <YouTubeEmbed videoUrl="https://www.youtube.com/embed/LLydyJ5cObg" />
        </div>
    );
}

export default PodcastComponent;