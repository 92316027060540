import React, { useState, useEffect } from 'react';
import { Modal, Button, Typography, Box } from '@mui/material';

const CookieBanner = () => {
    const [open, setOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);

    useEffect(() => {
        // Check if consent has already been given
        const consentGiven = localStorage.getItem('cookieConsent');
        if (!consentGiven) {
            setOpen(true);
        }
    }, []);

    const handleAccept = () => {
        setOpen(false);
        localStorage.setItem('cookieConsent', 'true'); // Store consent in localStorage
    };

    const handleOpenTerms = () => {
        setTermsOpen(true);
    };

    const handleCloseTerms = () => {
        setTermsOpen(false);
    };

    const handleOpenPrivacy = () => {
        setPrivacyOpen(true);
    };

    const handleClosePrivacy = () => {
        setPrivacyOpen(false);
    };

    return (
        <>
            {open && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        width: '100%',
                        bgcolor: 'grey.900',
                        color: 'white',
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 3,
                        flexDirection: {xs: 'column', lg: 'row'},
                        zIndex: 9999,
                    }}
                >
                    <Typography variant="body1">
                        We collect user data with Google Analytics. By using this site, you agree to these terms.
                    </Typography>
                    <Box>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={handleAccept}
                            sx={{ mr: 2 }}
                        >
                            Accept
                        </Button>
                        <Button 
                            variant="text" 
                            color="inherit" 
                            onClick={handleOpenTerms}
                            sx={{ mr: 1 }}
                        >
                            Terms of Service
                        </Button>
                        <Button 
                            variant="text" 
                            color="inherit" 
                            onClick={handleOpenPrivacy}
                        >
                            Privacy Policy
                        </Button>
                    </Box>
                </Box>
            )}

            {/* Terms of Service Modal */}
            <Modal open={termsOpen} onClose={handleCloseTerms}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'grey.900',
                        color: 'white',
                        p: 4,
                        width: '80%',
                        maxWidth: 600,
                        boxShadow: 3,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Terms of Service
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {/* Add your Terms of Service content here */}
                        By using this website, you consent to data collection as described in our privacy policy, which includes the use of Google Analytics for tracking and improving site performance.
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleCloseTerms}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>

            {/* Privacy Policy Modal */}
            <Modal open={privacyOpen} onClose={handleClosePrivacy}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'grey.900',
                        color: 'white',
                        p: 4,
                        width: '80%',
                        maxWidth: 600,
                        boxShadow: 3,
                        borderRadius: 2,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Privacy Policy
                    </Typography>
                    <Typography variant="body2" paragraph>
                        {/* Add your Privacy Policy content here */}
                        We value your privacy and collect user data only for site performance improvement through Google Analytics. No personally identifiable information is collected.
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleClosePrivacy}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default CookieBanner;
