import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

// New Quotes for Version 2
const quotes = [
  // Stoic Quotes
  "The only way to deal with an unfree world is to become so absolutely free that your very existence is an act of rebellion. – Albert Camus",
  "You have power over your mind – not outside events. Realize this, and you will find strength. – Marcus Aurelius",
  "If you want to be rich, do not add to your money, but add to your wisdom. – Seneca",
  "He who is brave is free. – Seneca",

  // Tech Leaders Quotes
  "Innovation distinguishes between a leader and a follower. – Steve Jobs",
  "We need to be mindful of the technological advances and the impact they have on the society. – Tim Berners-Lee",
  "The goal is not to be better than the other man, but your previous self. – Dalai Lama",
  "Technology is best when it brings people together. – Matt Mullenweg",
  "It is not the strongest of the species that survive, nor the most intelligent, but the one most responsive to change. – Charles Darwin",
];

// Ticker component
const FancyTickerV2 = () => {
  const [currentQuote, setCurrentQuote] = useState(0);

  // Change quote every 7 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuote((prevQuote) => (prevQuote + 1) % quotes.length);
    }, 7000); // Adjust timing as needed

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundColor: '#1a1a1a',
        color: '#fff',
        padding: '10px 20px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          height: '100px',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          // Responsive font size and padding adjustments
          '@media (max-width: 768px)': {
            padding: '10px',
          },
          '@media (max-width: 600px)': {
            padding: '5px',
          },
        }}
      >
        <motion.div
          key={currentQuote}
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ duration: 1 }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              whiteSpace: 'normal', // Allow text wrapping
              textAlign: 'center',
              // Responsive font size
              '@media (max-width: 768px)': {
                fontSize: '16px',
              },
              '@media (max-width: 600px)': {
                fontSize: '14px',
              },
            }}
          >
            {quotes[currentQuote]}
          </Typography>
        </motion.div>
      </Box>
    </motion.div>
  );
};

export default FancyTickerV2;
