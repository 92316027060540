import React from 'react';
import styles from '../styles/BlogPost.module.css'; // Using the established CSS file structure
import logo from './NealFrazier.png'; // Consistent branding with the Neal Frazier logo
import BlogNavBar from '../BlogNavBar';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const blogData = {
  title: "The Philosophy of Stoicism: An Introduction",
  summary: "Stoicism, an ancient Greek philosophy, teaches the development of self-control and fortitude as a means of overcoming destructive emotions."
}

const IntroToStoicismBlog = () => {
  return (
    <article className={styles.blogPost}>
      <SEO title={blogData.title} summary={blogData.summary} image={logo} />
      <header className={styles.blogHeader}>
        <Link to="/nftblogs">
        <img src={logo} alt="The Philosophy of Stoicism: An Introduction" />
        </Link>
        <h1>The Philosophy of Stoicism: An Introduction</h1>
      </header>
      <section className={styles.blogContent}>
        <p>
          Stoicism, an ancient Greek philosophy, teaches the development of self-control and fortitude as a means of overcoming destructive emotions. It's not just a scholarly interest but a practical philosophy for a more resilient and meaningful life. This introduction explores its origins, key principles, and how it can be applied today.
        </p>
        <h2>Origins of Stoicism</h2>
        <p>
          Founded in Athens by Zeno of Citium in the early 3rd century BC, Stoicism became one of the most influential philosophies of the ancient world. For a historical overview, <a href="https://www.iep.utm.edu/stoicism/" target="_blank" rel="noopener noreferrer">The Internet Encyclopedia of Philosophy</a> offers an in-depth look.
        </p>
        <h2>Core Principles of Stoicism</h2>
        <p>
          Stoicism is built on the foundation of logic, ethics, and physics, advocating for a life in harmony with nature and guided by virtue. It’s about understanding what we can control and relinquishing concern over what we cannot. <a href="https://dailystoic.com/what-is-stoicism-overview-definition-10-stoic-principles/" target="_blank" rel="noopener noreferrer">Daily Stoic</a> provides a concise summary of these principles.
        </p>
        <h2>Stoicism Today</h2>
        <p>
          Modern Stoicism shows us how ancient wisdom can still be relevant in dealing with today’s challenges. It teaches resilience, gratitude, and how to lead a fulfilling life. To dive deeper into its modern applications, check out <a href="https://modernstoicism.com/" target="_blank" rel="noopener noreferrer">Modern Stoicism</a>.
        </p>
        <h2>Influential Stoic Philosophers</h2>
        <p>
          Seneca, Epictetus, and Marcus Aurelius are among the most notable Stoic philosophers, whose works have survived and continue to influence. For insights into their teachings, <a href="https://plato.stanford.edu/entries/stoicism/" target="_blank" rel="noopener noreferrer">Stanford Encyclopedia of Philosophy</a> offers detailed analyses.
        </p>
        <h2>Practicing Stoicism</h2>
        <p>
          Adopting Stoic practices such as journaling, meditation, and mindfulness can lead to significant personal growth and improved mental health. Begin your practice with <a href="https://howtobeastoic.wordpress.com/" target="_blank" rel="noopener noreferrer">How to Be a Stoic</a>, a guide filled with practical tips.
        </p>
      </section>
      <BlogNavBar />
    </article>
  );
};

export default IntroToStoicismBlog;
