import React from 'react';
import { Container, Typography, Grid, Box, Button, Divider } from '@mui/material';
import { motion } from 'framer-motion';
import { DeviceFrameset } from 'react-device-frameset';
import { Parallax } from 'react-scroll-parallax';

const projects = [
  {
    title: 'All PC Repair',
    description: 'All PC Repair delivers expert IT solutions to homes and businesses throughout Hampton Roads. From on-site computer repairs and system upgrades to full-scale IT management, they ensure seamless technology experiences. Their services boost productivity, strengthen security, and streamline operations for small businesses while offering quick, reliable fixes for personal devices.',
    link: 'https://allpcrepairva.com',
    mobileScreenshot: 'allpcrepair-mobile-view.png',
    alt: 'All PC Repair website mobile view',
  },
  {
    title: 'Family Reunite Network',
    description: 'The Family Reunite Network, spearheaded by T.J. Scott, offers compassionate support to families facing separation due to immigration. Serving Hampton Roads, the network provides resources, advocacy, and assistance, helping families navigate legal and social challenges while fostering connections and hope.',
    link: 'https://familyreunitenetwork.com',
    mobileScreenshot: 'familyreunitenetwork-mobile-view.png',
    alt: 'Family Reunite Network website mobile view',
  },
  {
    title: 'Deseo Media Company',
    description: 'Deseo Media Company is a creative powerhouse based in Virginia Beach, specializing in media production, branding, and business development. Their storytelling approach builds meaningful connections between brands and audiences, offering services from digital marketing to professional photography, videography, and web design.',
    link: 'https://deseomediacompany.com',
    mobileScreenshot: 'deseomediacompany-mobile-view.png',
    alt: 'Deseo Media Company website mobile view',
  },
  {
    title: 'Living Faith Christian Fellowship',
    description: 'Living Faith Christian Fellowship in Virginia Beach, Virginia, offers a warm and welcoming space for spiritual growth and community connection. With engaging services, events, and programs for all ages, the church creates opportunities for worship, learning, and shared experiences that inspire faith and belonging.',
    link: 'https://www.livingfaithchristianfellowship.com',
    mobileScreenshot: 'living-faith-christian-fellowship-mobile-view.png',
    alt: 'Living Faith Christian Fellowship website mobile view',
  },
  {
    title: 'Valet Ninjas',
    description: 'Valet Ninjas provides top-tier valet services for events and venues, ensuring smooth parking logistics with a focus on exceptional customer service. Whether for weddings, corporate gatherings, or private functions, Valet Ninjas delivers seamless and secure experiences tailored to clients’ needs.',
    link: 'https://www.valetninjas.website',
    mobileScreenshot: 'valet-ninjas-mobile-view.png',
    alt: 'Valet Ninjas website mobile view',
  },
  {
    title: 'Scott\'s Electric',
    description: 'Scott\'s Electric offers trusted electrical services in (Hampton Roads) Virginia Beach, Virginia, handling residential, commercial, and EV charging installations. Known for safety, reliability, and craftsmanship, they excel in wiring, panel upgrades, security systems, and comprehensive repairs tailored to each client’s needs.',
    link: 'https://www.scottselectric.pro',
    mobileScreenshot: 'scotts-electric-mobile-view.png',
    alt: 'Scott\'s Electric website mobile view',
  },
];


const Portfolio = () => {
  

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{ backgroundColor: '#121212', padding: '50px 0', color: '#fff' }}
    >
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" gutterBottom sx={{ marginBottom: '40px' }}>
          Featured Websites
        </Typography>

        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} key={index}>
              <motion.div whileHover={{ scale: 0.95 }} transition={{ duration: 0.3 }}>
                <Box
                  sx={{
                    backgroundColor: '#1e1e1e',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                    textAlign: 'center',
                  }}
                >
                  <Parallax speed={10}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        margin: 'auto',
                      }}
                    >
                      <DeviceFrameset  device="Samsung Galaxy S5" portrait="boolean" >
                        <img
                          src={project.mobileScreenshot}
                          alt={project.alt}
                          loading="lazy"
                          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                        />
                      </DeviceFrameset>
                    </Box>
                  </Parallax>

                  <Box sx={{ marginTop: 3 }}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
                      {project.title}
                    </Typography>
                    <Divider sx={{ marginBottom: 2 }} />
                    <Typography variant="body1" sx={{ color: '#ccc', marginBottom: '15px' }}>
                      {project.description}
                    </Typography>
                    <Button
                      variant="contained"
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        fontSize: '1rem',
                        '&:hover': {
                          background: 'linear-gradient(45deg, #ffffff 30%, #fff 90%)',
                          color: 'black',
                        },
                      }}
                    >
                      Visit Site
                    </Button>
                  </Box>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </motion.div>
  );
};

export default Portfolio;
