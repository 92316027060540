import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import logo from '../assets/neal-frazier-tech-logo.png';

const Hero = () => {
  const controls = useAnimation();
  const ref = useRef(null); // Ref for the section to observe
  const [animationKey, setAnimationKey] = useState(0);

  // Animation variants
  const imageVariants = {
    initial: { scale: 0.5, opacity: 1 },
    animate: { scale: 1.5, opacity: 0, transition: { duration: 5, ease: 'easeInOut' } },
  };

  const textVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { duration: 1.5, ease: 'easeOut' } },
  };

  // Intersection Observer callback
  const handleIntersection = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setAnimationKey(prevKey => prevKey + 1); // Increment key to reset animation
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1, // Trigger when 10% of the section is visible
    });

    const currentRef = ref.current; // Store ref in a variable
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  useEffect(() => {
    controls.start('animate');
  }, [animationKey, controls]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
      style={{ minHeight: '70vh', backgroundColor: '#121212', color: '#fff', position: 'relative' }}
      ref={ref} // Attach ref to the section
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '70vh',
            textAlign: 'center',
            position: 'relative',
            paddingTop: 10,
          }}
        >
          <motion.img
            key={animationKey} // Reset animation by changing key
            src={logo}
            alt="Logo"
            variants={imageVariants}
            initial="initial"
            animate="animate"
            style={{ width: 'auto', height: '30vh', position: 'absolute' }}
          />
          <motion.div
           key={animationKey}
            variants={textVariants}
            initial="initial"
            animate="animate"
            style={{ position: 'relative', zIndex: 1 }}
          >
            <Typography variant="h1" sx={{ mt: 3 }}>
              Welcome to Neal Frazier Tech
            </Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              Do Good, Be Great, Find A Purpose.
            </Typography>
            <Typography variant="h6" sx={{ mt: 1 }}>
              We don't just make websites...We help build futures.
            </Typography>
          </motion.div>
        </Box>
      </Container>
    </motion.div>
  );
};

export default Hero;
