import React from 'react';
import CountUp from 'react-countup';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import '../styles/StatsSection.css'; // Optional CSS for further styling

const StatsSection = () => {
  const stats = [
    { title: "Websites Built", end: 20 },
    { title: "People Helped", end: 150 },
  ];

  const { ref, inView } = useInView({ triggerOnce: false });

  return (
    <Box
      sx={{
        backgroundColor: '#121212', // Dark background
        color: 'white', // White text
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          className="stat-card"
          ref={ref}
          initial={{ opacity: 0, y: 50 }} // Start off-screen
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }} // Animate on view
          transition={{ duration: 1 }}
        >
          <Typography variant="h6" component="h6" gutterBottom>
            {stat.title}
          </Typography>
          {inView && (
            <CountUp className="Lcd-Font" start={0} end={stat.end} duration={5} separator="," />
          )}
        </motion.div>
      ))}
    </Box>
  );
};

export default StatsSection;
