import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Environment } from '@react-three/drei';
import styled from 'styled-components';

// Button to prompt scrolling down
const ScrollButton = styled.button`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const MediaCardParallaxScene = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Determine if the screen size is mobile
  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Handle scrolling past the scene
  const handleScroll = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <Canvas style={{ height: '100vh', backgroundColor: '#101010' }} camera={{ position: [0, 0, 20], fov: 45 }}>
        <ambientLight intensity={0.5} color="#ff007f" />
        <pointLight position={[10, 10, 10]} intensity={1} color="#00ffcc" />
        <Environment preset="night" />

        {/* Add a variety of shapes */}
        <AnimatedShape type="sphere" color="#ff00ff" position={[5, 3, 0]} />
        <AnimatedShape type="torus" color="#00ffff" position={[-5, -3, 0]} />
        <AnimatedShape type="box" color="#ffdd00" position={[3, -5, -2]} />
        <AnimatedShape type="sphere" color="#00ffcc" position={[-3, 5, 2]} />
        <AnimatedShape type="torusKnot" color="#ff6600" position={[0, 0, -5]} />
        <AnimatedShape type="octahedron" color="#00ffcc" position={[0, -5, 5]} />

        {/* Enable OrbitControls only on larger screens */}
        {!isMobile && <OrbitControls enableZoom={false} />}
      </Canvas>

      {/* Scroll button for mobile users */}
      {isMobile && (
        <ScrollButton onClick={handleScroll}>
          Scroll Down
        </ScrollButton>
      )}
    </div>
  );
};

export default MediaCardParallaxScene;

// Component for each shape with custom animations
function AnimatedShape({ type, color, position }) {
  const meshRef = useRef();

  // Rotate and pulse each shape uniquely
  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    if (meshRef.current) {
      meshRef.current.rotation.x = elapsedTime * 0.3;
      meshRef.current.rotation.y = elapsedTime * 0.5;
      meshRef.current.scale.set(
        Math.sin(elapsedTime) * 0.3 + 1.5,
        Math.sin(elapsedTime * 0.5) * 0.3 + 1.5,
        Math.sin(elapsedTime * 0.7) * 0.3 + 1.5
      );
    }
  });

  return (
    <mesh ref={meshRef} position={position}>
      {type === 'sphere' && <sphereGeometry args={[1, 32, 32]} />}
      {type === 'torus' && <torusGeometry args={[1, 0.4, 16, 100]} />}
      {type === 'box' && <boxGeometry args={[1.5, 1.5, 1.5]} />}
      {type === 'torusKnot' && <torusKnotGeometry args={[1, 0.3, 100, 16]} />}
      {type === 'octahedron' && <octahedronGeometry args={[1, 0]} />}
      
      <meshStandardMaterial color={color} emissive={color} emissiveIntensity={0.6} />
    </mesh>
  );
}
