import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ title, summary, image }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={summary} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={summary} />
    <meta property="og:image" content={image} />
  </Helmet>
);

export default SEO;
