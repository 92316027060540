import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { motion } from 'framer-motion';
import '../styles/Services.css'; 
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet-async';

const services = [
  {
    title: 'Web Development',
    description: `We specialize in creating custom, responsive, and high-performing websites that are designed to enhance user experience and drive engagement. Whether it's a simple portfolio or a full-scale enterprise solution, our cutting-edge web development practices ensure your site stands out in the digital landscape.`,
    details: `Our expertise includes but is not limited to:
      - Responsive Web Design: Ensuring your website looks great and functions perfectly on all devices.
      - Performance Optimization: Optimizing your website for fast load times and smooth user interactions.
      - SEO Best Practices: Implementing SEO strategies to improve visibility and ranking on search engines.
      - Custom Backend Development: Creating tailored backend systems to support your website's needs.
      - Third-Party API Integration: Seamlessly integrating third-party APIs for enhanced functionality.`
  },
  {
    title: 'Software Engineering',
    description: `Our software engineering services focus on developing robust, scalable solutions that transform your business. From startups to large corporations, we provide innovative technology tailored to meet your unique needs.`,
    details: `Services we provide include:
      - System Architecture Design: Designing robust architectures for your software projects.
      - Full-Stack Development: Developing complete solutions from backend to frontend.
      - Automation & Custom Tools: Building custom tools to automate your workflows.
      - Cloud Solutions & Deployments: Deploying and managing scalable cloud-based infrastructures.
      - DevOps & Continuous Integration: Streamlining development and deployment processes.`
  },
  {
    title: 'Project Management',
    description: `Delivering your projects efficiently and effectively is our top priority. Our project management services ensure clear communication, transparency, and meeting deadlines without compromising on quality.`,
    details: `Our approach includes:
      - Agile Methodologies: Adopting agile practices for flexible and efficient project management.
      - Risk Management: Identifying and mitigating risks throughout the project lifecycle.
      - Budgeting & Resource Allocation: Ensuring resources are used efficiently to maximize value.
      - Team Collaboration Tools: Implementing collaboration tools for smooth communication.
      - Milestone Tracking & Reporting: Monitoring progress with detailed reports and milestones.`
  },
  {
    title: 'Web Valet Services',
    description: `We offer premium web valet services to handle all of your website maintenance and optimization needs. Our team ensures your website remains up-to-date, secure, and running smoothly without downtime.`,
    details: `Key services:
      - Website Monitoring: Continuous monitoring to detect and resolve issues before they impact users.
      - Regular Security Audits: Conducting frequent security checks to protect your site from threats.
      - Plugin & Software Updates: Keeping your website's software and plugins updated for performance.
      - Performance Tuning: Optimizing your website to load faster and run more efficiently.
      - Backup & Recovery Solutions: Implementing backup strategies to ensure data safety and quick recovery.`
  },
  {
    title: 'Cyber Security',
    description: `We provide top-tier cyber security services to protect your business from the evolving landscape of digital threats. From vulnerability assessments to implementing advanced defense systems, we ensure your infrastructure stays secure.`,
    details: `Our expertise includes:
      - Vulnerability Testing: Identifying and fixing vulnerabilities in your systems and applications.
      - Incident Response & Recovery: Providing rapid response to cyber incidents to minimize damage.
      - Network Security Solutions: Securing your network with firewalls, encryption, and more.
      - Cloud Security Management: Ensuring your cloud-based systems are secure and compliant.
      - Encryption and Data Protection: Implementing encryption protocols to protect sensitive data.`
  },
  {
    title: 'Data Management',
    description: `Our reliable data management services ensure your data is secure, accessible, and organized. Whether it's migrating data, setting up databases, or streamlining your existing systems, we’ve got you covered.`,
    details: `We specialize in:
      - Data Migration & Integration: Moving and integrating data with minimal disruption.
      - Database Management: Setting up and managing relational and non-relational databases.
      - Data Backup & Disaster Recovery: Ensuring your data is backed up and recoverable in case of an emergency.
      - Big Data Solutions: Handling large data sets with cutting-edge technologies.
      - Data Analysis & Reporting: Providing actionable insights from your data.`
  },
  {
    title: 'E-Commerce Solutions',
    description: `Our comprehensive e-commerce solutions are designed to help you sell online effectively and provide a seamless shopping experience for your customers. From setting up your online store to implementing payment gateways, we ensure your e-commerce platform runs smoothly.`,
    details: `We offer:
      - Custom E-Commerce Platforms: Building tailored e-commerce solutions for your business needs.
      - Payment Gateway Integration: Integrating secure payment methods for a smooth checkout process.
      - Shopping Cart Solutions: Developing and optimizing shopping cart functionality.
      - Product Catalog Management: Managing your product listings and ensuring easy navigation.
      - Sales & Marketing Tools: Implementing tools to boost sales and enhance customer engagement.`
  },
  {
    title: 'Mobile App Development',
    description: `We create mobile applications that offer an excellent user experience across different platforms. Whether it's native or cross-platform development, we focus on building fast, secure, and intuitive mobile apps.`,
    details: `Our mobile app services include:
      - iOS & Android Development: Creating apps for both iOS and Android platforms.
      - Cross-Platform Solutions: Developing apps that work seamlessly across devices.
      - App Store Optimization: Ensuring your app ranks well and is easy to find on app stores.
      - UX/UI Design: Designing intuitive user interfaces for a smooth experience.
      - App Maintenance & Updates: Providing ongoing support and updates to keep your app running.`
  },
  {
    title: 'AI and Machine Learning',
    description: `Our AI and machine learning services empower businesses to harness the power of data and drive innovation. From predictive analytics to automated systems, we help you integrate AI to enhance efficiency and decision-making.`,
    details: `We specialize in:
      - Predictive Analytics: Using data to predict future trends and opportunities.
      - Natural Language Processing (NLP): Enabling machines to understand and interact with human language.
      - Machine Learning Models: Developing algorithms to automate decision-making processes.
      - Automation & AI Integration: Integrating AI into existing systems to streamline operations.
      - Data Science & Deep Learning: Leveraging deep learning techniques for complex problem-solving.`
  }
];

const ServicesPage = () => {
  return (
    <>
    <Helmet>
    <title>Virginia Beach, Virginia Digital Services | Neal Frazier Tech</title>
    <meta
      name="description"
      content="Explore our wide range of services including web development, software engineering, project management, cyber security, and more. We provide top-notch services tailored to meet your business needs."
    />
    <meta
      name="keywords"
      content="web development, software engineering, project management, cyber security, mobile app development, AI, machine learning, e-commerce solutions"
    />
    <meta property="og:title" content="Our Services | Neal Frazier Tech" />
    <meta
      property="og:description"
      content="Learn more about the services we offer and how we can help your business grow."
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://nealfrazier.tech/services" />
    <meta property="og:image" content="https://nealfrazier.tech/assets/newlogo.png" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Our Services | Neal Frazier Tech" />
    <meta
      name="twitter:description"
      content="Explore our services and how we can help you achieve your business goals."
    />
  </Helmet>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{ backgroundColor: '#121212', paddingTop: 200, color: '#fff' }}
    >
      <ParallaxProvider>
        <Container maxWidth="md" sx={{ minHeight: '100vh' }}>
          <Parallax speed={10}>
            <Typography 
              id="services" 
              variant="h2" 
              align="center" 
              gutterBottom 
              sx={{ marginBottom: '40px', color: '#fff' }}
            >
              Our Services
            </Typography>
          </Parallax>

          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} key={index}>
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.3 }}
                >
                  <Box
                    className='box' 
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      backgroundColor: '#1e1e1e',
                      padding: '30px',
                      borderRadius: '10px',
                      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                      height: 'auto',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <Typography variant="h4" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
                      {service.title}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#ccc', marginBottom: '1rem', textAlign: 'center' }}>
                      {service.description}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'white', backgroundColor: 'grey', whiteSpace: 'pre-wrap', textAlign: 'left', padding: 5, borderRadius: 2, fontSize: 15 }}>
                      {service.details}
                    </Typography>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </ParallaxProvider>
    </motion.div>
    </>
  );
};

export default ServicesPage;
