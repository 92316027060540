import React from 'react';
import styles from '../styles/BlogPost.module.css'; 
import logo from './NealFrazier.png'; 
import BlogNavBar from '../BlogNavBar';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const blogData = {
  title: "Understanding the Basics of Cryptography",
  summary: "Cryptography plays a pivotal role in securing digital communication, ensuring that our data remains private and protected."
}

const BasicsOfCryptographyBlog = () => {
  return (
    <article className={styles.blogPost}>
       <SEO title={blogData.title} summary={blogData.summary} image={logo} />
      <header className={styles.blogHeader}>
        <Link to="/nftblogs">
        <img src={logo} alt="Understanding the Basics of Cryptography" />
        </Link>
        <h1>Understanding the Basics of Cryptography</h1>
      </header>
      <section className={styles.blogContent}>
        <p>
          Cryptography plays a pivotal role in securing digital communication, ensuring that our data remains private and protected. This blog post explores the fundamentals of cryptography, its importance in the digital age, and how it helps safeguard our information online.
        </p>
        <h2>What is Cryptography?</h2>
        <p>
          At its core, cryptography is the art of writing or solving codes. It enables secure communication in the presence of adversaries, ensuring that only the intended recipients can read the encrypted messages. For a deeper understanding, visit <a href="https://www.khanacademy.org/computing/computer-science/cryptography" target="_blank" rel="noopener noreferrer">Khan Academy's Cryptography Course</a>.
        </p>
        <h2>Types of Cryptography</h2>
        <p>
          There are several types of cryptographic techniques, including symmetric-key cryptography, asymmetric-key cryptography, and hash functions. Each serves a different purpose but together they provide the foundation for secure communications. Learn more about these types at <a href="https://www.sciencedirect.com/topics/computer-science/asymmetric-cryptography" target="_blank" rel="noopener noreferrer">ScienceDirect</a>.
        </p>
        <h2>Encryption and Decryption</h2>
        <p>
          Encryption is the process of converting plain text into a coded form (ciphertext) to prevent unauthorized access, while decryption is the process of converting ciphertext back to plain text. The <a href="https://searchsecurity.techtarget.com/definition/encryption" target="_blank" rel="noopener noreferrer">Encryption Guide on TechTarget</a> offers a comprehensive overview.
        </p>
        <h2>Public and Private Keys</h2>
        <p>
          In asymmetric cryptography, two keys are used: a public key for encryption and a private key for decryption. This method allows anyone to encrypt a message but only allows the holder of the paired private key to decrypt it. For an in-depth explanation, see <a href="https://www.cloudflare.com/learning/ssl/what-is-asymmetric-encryption/" target="_blank" rel="noopener noreferrer">Cloudflare's Guide</a>.
        </p>
        <h2>Applications of Cryptography</h2>
        <p>
          Beyond securing emails and online transactions, cryptography is essential in protecting passwords, digital identities, and ensuring the integrity of data transmitted across networks. Discover the wide range of applications at <a href="https://www.comptia.org/blog/what-is-cryptography" target="_blank" rel="noopener noreferrer">CompTIA's Overview</a>.
        </p>
      </section>
      <BlogNavBar />
    </article>
  );
};

export default BasicsOfCryptographyBlog;
