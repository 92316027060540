import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faGithub,
  faDiscord,
  faSoundcloud,
  faXTwitter,
  faCodepen,
  faInstagramSquare
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const FooterContainer = styled.footer`
  padding: 40px 20px;
  text-align: center;
  background-color: #f8f9fa;
  color: #333;
  position: relative;
  z-index: 9999;
  max-width: 100vw;
  overflow-x: hidden;
`;

const Logo = styled.img`
  width: 100px;
  margin-bottom: 20px;
`;

const SocialContainer = styled.div`
  margin: 10px 0;
`;

const SocialLink = styled.a`
  margin: 0 10px;
  color: #333;
  font-size: 24px;

  &:hover {
    color: #007bff;
  }
`;

const TimeAndDate = styled.div`
  margin-top: 20px;
  font-size: 16px;
`;

const Locations = styled.div`
  margin-top: 20px;
  font-size: 14px;
  color: #666;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LocationItem = styled.span`
  margin: 0 5px;
`;

const Directors = styled.div`
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const DirectorLink = styled.a`
  margin: 0 10px;
  color: #333;

  &:hover {
    color: #007bff;
  }
`;

const Copyright = styled.div`
  margin-top: 20px;
  font-size: 14px;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const currentDate = new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  return (
    <FooterContainer>
      {/* Logo */}
      <Logo src="/assets/logo.png" alt="Neal Frazier Tech Logo" />

      {/* Social Icons */}
      <SocialContainer>
        <SocialLink href="https://twitter.com/NealFrazierTech" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faXTwitter} />
        </SocialLink>
        <SocialLink href="https://www.linkedin.com/in/NealFrazier" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </SocialLink>
        <SocialLink href="https://github.com/1nc0gn30" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} />
        </SocialLink>
        <SocialLink href="mailto:business@nealfrazier.tech" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faEnvelope} />
        </SocialLink>
        <SocialLink href="https://discord.gg/qwBtx9NY" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faDiscord} />
        </SocialLink>
        <SocialLink href="https://soundcloud.com/1nc0gn30" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faSoundcloud} />
        </SocialLink>
        <SocialLink href="https://codepen.io/Neal-Frazier" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faCodepen} />
        </SocialLink>
        <SocialLink href="https://instagram.com/nealfraziertech" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagramSquare} />
        </SocialLink>
      </SocialContainer>

      {/* Locations for SEO */}
      <Locations>
        <strong>Locations Served:</strong>
        <LocationItem>Virginia Beach</LocationItem>
        <LocationItem>Norfolk</LocationItem>
        <LocationItem>Chesapeake</LocationItem>
        <LocationItem>Portsmouth</LocationItem>
        <LocationItem>Newport News</LocationItem>
        <LocationItem>Hampton</LocationItem>
        <LocationItem>Suffolk</LocationItem>
      </Locations>

      {/* Directors Section */}
      <Directors>
        <strong>Explore:</strong>
        <DirectorLink href="/about">About</DirectorLink>
        <DirectorLink href="/services">Services</DirectorLink>
        <DirectorLink href="/media">Media</DirectorLink>
        <DirectorLink href="/contact">Contact</DirectorLink>
        <DirectorLink href="/nftblogs">Blog</DirectorLink>
      </Directors>

      {/* Time and Date */}
      <TimeAndDate>
        {currentTime} - {currentDate}
      </TimeAndDate>

      {/* Copyright */}
      <Copyright>
        &copy; {currentYear} Neal Frazier Tech. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
