import React from 'react';
import styles from '../styles/BlogPost.module.css'; // Established CSS file structure
import logo from './NealFrazier.png'; // Consistent branding with the Neal Frazier logo
import BlogNavBar from '../BlogNavBar';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const blogData = {
  title: "Fruitarianism: A Guide to Fruit-Based Diets",
  summary: "Fruitarianism, a subset of veganism, is a diet that primarily consists of raw fruits."
}

const FruitarianismGuideBlog = () => {
  return (
    <article className={styles.blogPost}>
      <SEO title={blogData.title} summary={blogData.summary} image={logo} />
      <header className={styles.blogHeader}>
        <Link to="/nftblogs">
        <img src={logo} alt="Fruitarianism: A Guide to Fruit-Based Diets" />
        </Link>
        <h1>Fruitarianism: A Guide to Fruit-Based Diets</h1>
      </header>
      <section className={styles.blogContent}>
        <p>
          Fruitarianism, a subset of veganism, is a diet that primarily consists of raw fruits. Advocates believe it to be the most natural form of consumption, offering numerous health benefits. This guide introduces the principles of fruitarianism, its potential benefits, and considerations for those curious about adopting this lifestyle.
        </p>
        <h2>What is Fruitarianism?</h2>
        <p>
          At its core, fruitarianism involves consuming a diet made up of 75% or more raw fruit. The remaining diet can include nuts, seeds, and other plant-based foods. For a deeper understanding, <a href="https://www.healthline.com/nutrition/fruitarian-diet" target="_blank" rel="noopener noreferrer">Healthline’s Fruitarian Diet Overview</a> offers a comprehensive look.
        </p>
        <h2>Benefits of a Fruit-Based Diet</h2>
        <p>
          Proponents of fruitarianism report increased energy levels, improved digestion, and a lower risk of heart disease. Fruits are high in vitamins, minerals, and fiber while being low in calories. Explore the benefits at <a href="https://www.medicalnewstoday.com/articles/323047" target="_blank" rel="noopener noreferrer">Medical News Today</a>.
        </p>
        <h2>Challenges and Considerations</h2>
        <p>
          Transitioning to a fruit-based diet requires careful planning to avoid nutritional deficiencies, particularly in protein, calcium, and essential fatty acids. It's crucial to diversify fruit intake and supplement the diet with nuts and seeds. For more on potential challenges, see <a href="https://www.webmd.com/diet/fruitarian-diet" target="_blank" rel="noopener noreferrer">WebMD’s Fruitarian Diet Guide</a>.
        </p>
        <h2>How to Start with Fruitarianism</h2>
        <p>
          Beginners should gradually increase their fruit intake, starting with incorporating a variety of fruits into each meal. Consulting with a nutritionist can also ensure nutritional needs are met. Get started with practical tips from <a href="https://nutriciously.com/fruitarian-diet/" target="_blank" rel="noopener noreferrer">Nutriciously’s Guide to Fruitarianism</a>.
        </p>
        <h2>Is Fruitarianism Right for You?</h2>
        <p>
          While fruitarianism offers health benefits, it's not suitable for everyone. Individual health conditions, dietary needs, and lifestyle factors should be considered before making significant dietary changes. Engage in further reading and consultation to make an informed decision.
        </p>
      </section>
      <BlogNavBar />
    </article>
  );
};

export default FruitarianismGuideBlog;
