import React from 'react';
import styles from '../styles/BlogPost.module.css'; // Ensure this matches your file structure
import logo from './NealFrazier.png'; // Update this with the path to your web accessibility logo
import BlogNavBar from '../BlogNavBar';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const blogData = {
  title: "Web Accessibility Essentials: Making Your Site Accessible to Everyone",
  summary: "Web accessibility is the practice of ensuring there are no barriers that prevent interaction with, or access to, websites on the World Wide Web by people with physical disabilities, situational disabilities, and socio-economic restrictions on bandwidth and speed."
}

const WebAccessibilityBlog = () => {
    return (
      <article className={styles.blogPost}>
        <SEO title={blogData.title} summary={blogData.summary} image={logo} />
        <header className={styles.blogHeader}>
          <Link to="/nftblogs">
          <img src={logo} alt="Web Accessibility Essentials 2024" />
          </Link>
          <h1>Web Accessibility Essentials: Making Your Site Accessible to Everyone</h1>
        </header>
        <section className={styles.blogContent}>
          <p>
            Web accessibility is the practice of ensuring there are no barriers that prevent interaction with, or access to, websites on the World Wide Web by people with physical disabilities, situational disabilities, and socio-economic restrictions on bandwidth and speed. This guide dives into the principles and practices necessary to make your web content accessible to everyone.
          </p>
          <h2>1. Understanding Web Accessibility</h2>
          <p>
            Before diving into specific practices, it's crucial to understand what web accessibility means and why it's important. The <a href="https://www.w3.org/WAI/fundamentals/accessibility-intro/" target="_blank" rel="noopener noreferrer">Web Accessibility Initiative (WAI)</a> provides a comprehensive introduction to web accessibility, including the rationale behind it and the various standards in place to ensure web content is accessible.
          </p>
          <h2>2. The Web Content Accessibility Guidelines (WCAG)</h2>
          <p>
            The WCAG documents explain how to make web content more accessible to people with disabilities. They are part of a series of web accessibility guidelines published by the WAI of the World Wide Web Consortium (W3C). Learn more about the guidelines at <a href="https://www.w3.org/WAI/standards-guidelines/wcag/" target="_blank" rel="noopener noreferrer">WCAG Overview</a>.
          </p>
          <h2>3. Accessible Rich Internet Applications (ARIA)</h2>
          <p>
            ARIA defines a way to make web content and web applications more accessible to people with disabilities. It helps with dynamic content and advanced user interface controls developed with Ajax, HTML, JavaScript, and related technologies. Explore ARIA at <a href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA" target="_blank" rel="noopener noreferrer">MDN Web Docs</a>.
          </p>
          <h2>4. Designing for Screen Readers</h2>
          <p>
            Screen readers are software applications that attempt to convey what people with normal eyesight see on a display to their users via non-visual means. Designing for screen readers means ensuring your website can be navigated and understood when presented through these tools. Tips for designing for screen readers can be found on <a href="https://webaim.org/techniques/screenreader/" target="_blank" rel="noopener noreferrer">WebAIM</a>.
          </p>
          <h2>5. Keyboard Navigation</h2>
          <p>
            Many users rely on a keyboard to navigate online content. Ensuring your site is fully navigable via keyboard alone is a critical aspect of accessibility. Learn how to implement keyboard navigation on <a href="https://www.smashingmagazine.com/2019/02/accessibility-webinar/" target="_blank" rel="noopener noreferrer">Smashing Magazine</a>.
          </p>
          <h2>6. Color Contrast and Text Size</h2>
          <p>
            Color contrast and text size are vital for users with visual impairments. The right contrast can make content readable, while sufficient text size ensures that users can read without straining their eyes. The <a href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html" target="_blank" rel="noopener noreferrer">W3C offers guidelines</a> on minimum contrast and text size.
          </p>
          <h2>7. Testing for Accessibility</h2>
          <p>
            Regularly testing your website for accessibility is essential. This includes manual testing, automated tools, and user testing with people with disabilities. Resources for testing can be found at <a href="https://www.w3.org/WAI/test-evaluate/" target="_blank" rel="noopener noreferrer">WAI's Evaluation Tools Overview</a>.
          </p>
          <h2>8. Creating Accessible Forms</h2>
          <p>
            Forms are a common element on web pages but can pose significant accessibility challenges. Ensure all form elements are labeled correctly, and feedback is provided accessibly. Guidance on creating accessible forms is available at <a href="https://webaim.org/techniques/forms/" target="_blank" rel="noopener noreferrer">WebAIM</a>.
          </p>
          <h2>9. Multimedia Accessibility</h2>
          <p>
            If your site includes multimedia (video, audio), providing captions, transcripts, and audio descriptions makes your content accessible to all users, including those with hearing and visual impairments. Explore multimedia accessibility on <a href="https://www.w3.org/WAI/media/av/" target="_blank" rel="noopener noreferrer">W3C Media Accessibility User Requirements</a>.
          </p>
          <h2>10. Legal and Ethical Considerations</h2>
          <p>
            Beyond the technical aspects, it's important to understand the legal and ethical considerations of web accessibility. Not providing accessible web content can lead to discrimination and may have legal repercussions. The <a href="https://www.w3.org/WAI/policies/" target="_blank" rel="noopener noreferrer">WAI's Policies Relating to Web Accessibility</a> provides an overview of global laws and policies.
          </p>
        </section>
        <BlogNavBar />
      </article>
    );
  };
  
export default WebAccessibilityBlog;
