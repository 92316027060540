import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import * as THREE from 'three';

const PsychedelicStarScene = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Detect mobile screen size to adjust controls behavior
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return (
    <Canvas style={{ height: '100vh', backgroundColor: '#000' }} camera={{ position: [0, 0, 20], fov: 60 }}>
      <ambientLight intensity={0.3} />
      <pointLight position={[10, 10, 10]} intensity={1} color="#ff007f" />
      <pointLight position={[-10, -10, -10]} intensity={1} color="#00ffcc" />

      {/* Starry Background */}
      <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade speed={1} />

      {/* Psychedelic Shapes */}
      <MetallicStar position={[5, 3, 0]} />
      <MetallicStar position={[-5, -3, 0]} />
      <MetallicStar position={[3, -5, -2]} />
      <MetallicStar position={[-3, 5, 2]} />
      <MetallicStar position={[0, 0, -5]} />
      <MetallicStar position={[0, -5, 5]} />

      {/* Conditional OrbitControls */}
      {!isMobile && <OrbitControls enableZoom={false} />}
    </Canvas>
  );
};

export default PsychedelicStarScene;

function MetallicStar({ position }) {
  const meshRef = useRef();

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    if (meshRef.current) {
      meshRef.current.rotation.x = elapsedTime * 0.5;
      meshRef.current.rotation.y = elapsedTime * 0.6;
      meshRef.current.material.uniforms.u_time.value = elapsedTime;
    }
  });

  return (
    <mesh ref={meshRef} position={position}>
      <icosahedronGeometry args={[1, 0]} />
      
      <shaderMaterial
        attach="material"
        uniforms={{
          u_time: { value: 0 },
          u_color1: { value: new THREE.Color(0xff00ff) },
          u_color2: { value: new THREE.Color(0x00ffff) },
        }}
        fragmentShader={`
          uniform float u_time;
          uniform vec3 u_color1;
          uniform vec3 u_color2;

          void main() {
            vec2 uv = gl_FragCoord.xy / 1000.0;
            float wave = sin(uv.x * 10.0 + u_time * 5.0) + sin(uv.y * 10.0 + u_time * 5.0);
            float colorMix = (sin(u_time * 2.0) + 1.0) / 2.0;
            vec3 color = mix(u_color1, u_color2, colorMix * wave);
            gl_FragColor = vec4(color, 1.0);
          }
        `}
        vertexShader={`
          varying vec2 vUv;
          void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `}
      />
    </mesh>
  );
}
