import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import ContactForm from '../components/ContactForm';

const ContactPage = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#121212', // Dark background
        color: '#fff', // Text color
        minHeight: '100vh', // Full viewport height
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: '200px',
      }}
    >
      <Container maxWidth="md">
        

        <Typography
          variant="h6"
          align="center"
          sx={{
            fontFamily: 'Roboto, sans-serif',
            color: '#b0b0b0',
            marginBottom: 5,
          }}
        >
          We’d love to hear from you! Reach out with any questions or inquiries.
        </Typography>

        {/* Contact Form */}
        <ContactForm />
      </Container>
    </Box>
  );
};

export default ContactPage;
