import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { motion } from 'framer-motion';
import toolsData from '../components/NFTTools';  // Import the tools data

const ToolsShowcase = () => {
  // State to track if glitch effect is active
  const [glitchActive, setGlitchActive] = useState(true);
  const [tools] = useState(toolsData); // State to store tools and their order

  // Event listener to reset glitch effect on scroll
  useEffect(() => {
    const handleScroll = () => {
      setGlitchActive(true);  // Re-enable glitch effect on scroll
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to disable glitch on hover or click
  const disableGlitch = () => {
    setGlitchActive(false);
  };


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 10 }}
      style={{ backgroundColor: '#121212', padding: '50px 0', color: '#fff' }}
    >
     
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            sx={{ 
              marginBottom: '40px',
              fontFamily: 'Crypto-Crash-Font', // Glitchy font if you have one
              textShadow: glitchActive ? '0px 0px 5px #FF00FF, 0px 0px 10px #00FFFF' : 'none', // Apply glitch shadow when active
              filter: glitchActive ? 'hue-rotate(10deg)' : 'none', // Apply glitch effect only when active
              animation: glitchActive ? 'glitchText 1.5s infinite' : 'none', // Disable animation when not glitching
              transition: 'filter 0.3s, text-shadow 0.3s',  // Smooth transition to normal state
            }}
          >
            Languages & Tools We Use
          </Typography>

          <Grid container spacing={4}>
            {tools.map((tool, index) => (
              <Grid item xs={12} sm={6} md={4} key={tool.name}>
                <motion.div 
                
                  className="glitch-container"
              
                
                  onMouseEnter={disableGlitch} // Disable glitch on hover
                  onClick={disableGlitch}      // Disable glitch on click
                  style={{ cursor: 'grab' }} // Add grab cursor for better UI experience
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#1e1e1e',
                      borderRadius: '10px',
                      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                      padding: '20px',
                      textAlign: 'left',
                      height: '350px',
                      border: '2px solid rgba(255, 255, 255, 0.1)', // Add border to the box
                      transition: 'border-color 0.5s ease-in-out',
                      '&:hover': {
                        borderColor: '#00FFFF', // Glitchy border color on hover
                      }
                    }}
                  >
                    <Box
                      component="img"
                      src={tool.image}
                      alt={tool.name}
                      sx={{
                        width: 'auto',
                        height: '80px',
                        marginBottom: '15px',
                        filter: 'contrast(1.5) saturate(1.8) hue-rotate(60deg) brightness(1.3)',
                        transition: 'filter 0.5s ease, transform 0.5s ease',
                        border: '2px solid #ff00ff', // Adding a border to the images
                        boxShadow: '0px 0px 10px #FF00FF, 0px 0px 15px #00FFFF', // Glitchy shadow
                        '&:hover': {
                          filter: 'contrast(2) saturate(3) hue-rotate(120deg) brightness(1.5)',
                          transform: 'rotate(-2deg) skew(1deg)', // Slight rotation/skew on hover
                          boxShadow: '0px 0px 20px #00FFFF, 0px 0px 30px #FF00FF', // Intense shadow
                        },
                      }}
                    />
                    <Typography variant="h6" sx={{ 
                        color: '#fff', 
                        marginTop: '10px',
                        textShadow: glitchActive ? '0px 0px 5px #FF00FF, 0px 0px 10px #00FFFF' : 'none',
                        filter: glitchActive ? 'hue-rotate(10deg)' : 'none',
                        animation: glitchActive ? 'glitchText 1.5s infinite' : 'none',
                        transition: 'filter 0.3s, text-shadow 0.3s',  // Smooth transition to normal state
                    }}>
                      {tool.name}
                    </Typography>
                    <Typography variant="body2" sx={{ 
                        color: '#bdbdbd', 
                        marginTop: '10px',
                        textShadow: glitchActive ? '0px 0px 5px #FF00FF, 0px 0px 10px #00FFFF' : 'none',
                        filter: glitchActive ? 'hue-rotate(10deg)' : 'none',
                        animation: glitchActive ? 'glitchText 1.5s infinite' : 'none',
                        transition: 'filter 0.3s, text-shadow 0.3s',  // Smooth transition to normal state
                    }}>
                      {tool.description}
                    </Typography>
                    <Typography variant="caption" sx={{ 
                        color: '#bdbdbd', 
                        marginTop: '10px',
                        textShadow: glitchActive ? '0px 0px 5px #FF00FF, 0px 0px 10px #00FFFF' : 'none',
                        filter: glitchActive ? 'hue-rotate(10deg)' : 'none',
                        animation: glitchActive ? 'glitchText 1.5s infinite' : 'none',
                        transition: 'filter 0.3s, text-shadow 0.3s',  // Smooth transition to normal state
                    }}>
                      <strong>Use Case:</strong> {tool.useCase}
                    </Typography>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
     
    </motion.div>
  );
};

export default ToolsShowcase;
