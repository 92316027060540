import React from 'react';
import styles from '../styles/BlogPost.module.css'; // Ensure this matches your file structure
import logo from './NealFrazier.png';
import BlogNavBar from '../BlogNavBar';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const blogData = {
  title: "Web Development Fundamentals: Dive Into HTML, CSS, & JavaScript",
  summary: "Understanding the core technologies of web development is crucial for creating responsive, dynamic websites."
}

const WebDevFundamentalsBlog = () => {
    return (
      <article className={styles.blogPost}>
        <SEO title={blogData.title} summary={blogData.summary} image={logo} />
        <header className={styles.blogHeader}>
          <Link to="/nftblogs">
          <img src={logo} alt="Web Development Fundamentals 2024" />
          </Link>
          <h1>Web Development Fundamentals: Dive Into HTML, CSS, & JavaScript</h1>
        </header>
        <section className={styles.blogContent}>
          <p>
            Understanding the core technologies of web development is crucial for creating responsive, dynamic websites. This guide covers fundamental concepts and resources for HTML, CSS, and JavaScript, essential for any aspiring web developer.
          </p>
          <h2>1. HTML: The Backbone of the Web</h2>
          <p>
            HTML (HyperText Markup Language) is the standard markup language for creating web pages. It provides the basic structure of sites, which is then enhanced and modified by other technologies like CSS and JavaScript. Start with the <a href="https://developer.mozilla.org/en-US/docs/Learn/HTML/Introduction_to_HTML" target="_blank" rel="noopener noreferrer">MDN Web Docs guide</a> to understand HTML's role in web development.
          </p>
          <h2>2. CSS: Styling the Web</h2>
          <p>
            CSS (Cascading Style Sheets) is used for styling and laying out web pages — for example, changing the font, color, and spacing of your content, splitting it into multiple columns, or adding animations and other decorative features. The <a href="https://css-tricks.com/" target="_blank" rel="noopener noreferrer">CSS-Tricks website</a> offers excellent resources and guides for mastering CSS.
          </p>
          <h2>3. JavaScript: Bringing Pages to Life</h2>
          <p>
            JavaScript is a programming language that allows you to implement complex features on web pages — every time a web page does more than just sit there and display static information for you to look at — displaying timely content updates, interactive maps, animated 2D/3D graphics, scrolling video jukeboxes, etc. — JavaScript is probably involved. The <a href="https://javascript.info/" target="_blank" rel="noopener noreferrer">JavaScript.info website</a> is a great place to start learning JavaScript.
          </p>
          <h2>4. Responsive Web Design</h2>
          <p>
            Responsive web design makes your web page look good on all devices (desktops, tablets, and phones). CSS is used to resize, hide, shrink, enlarge, or move the content to make it look good on any screen. Learn more about responsive design on the <a href="https://www.w3schools.com/css/css_rwd_intro.asp" target="_blank" rel="noopener noreferrer">W3Schools Responsive Web Design Tutorial</a>.
          </p>
          <h2>5. Web Accessibility</h2>
          <p>
            Accessibility is the practice of making your websites usable by as many people as possible. We traditionally think of this as being about people with disabilities, but the practice benefits all users. Dive into web accessibility with the <a href="https://webaim.org/intro/" target="_blank" rel="noopener noreferrer">WebAIM Introduction to Web Accessibility</a>.
          </p>
          <h2>6. Front-End Frameworks</h2>
          <p>
            While not strictly part of the "fundamentals," understanding at least one modern front-end JavaScript framework or library, like React, Angular, or Vue.js, can be immensely beneficial. These tools can help you build more complex, interactive, and modern web applications. Start exploring React with the official <a href="https://reactjs.org/tutorial/tutorial.html" target="_blank" rel="noopener noreferrer">React documentation</a>.
          </p>
          <h2>7. Version Control with Git</h2>
          <p>
            Version control is an essential tool for developers, allowing you to track and manage changes to your codebase. Git, coupled with GitHub, offers a robust platform for version control and collaboration. The <a href="https://git-scm.com/book/en/v2" target="_blank" rel="noopener noreferrer">Pro Git book</a> is a comprehensive resource to learn Git.
          </p>
          <h2>8. Building and Deployment</h2>
          <p>
            Understanding how to package and deploy your web application is crucial. Familiarize yourself with build tools like Webpack and deployment platforms like Netlify and Vercel. The <a href="https://webpack.js.org/guides/getting-started/" target="_blank" rel="noopener noreferrer">Webpack Guides</a> are a great starting point for learning about modern web application deployment strategies.
          </p>
        </section>
        <BlogNavBar />
      </article>
    );
  };
  
  export default WebDevFundamentalsBlog;