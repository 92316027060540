import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { motion } from 'framer-motion';
import '../styles/Services.css'; 
import { Parallax } from 'react-scroll-parallax';

const services = [
  {
    title: 'Web Development',
    image: '/assets/WebDevelopment.png',
    description: 'Delivering high-performance websites built with modern frameworks to boost visibility, drive conversions, and dominate the digital landscape.',
    useCase: 'A local bakery needed an online presence to handle orders efficiently. Our interactive website helped them reach more customers and streamline operations.'
  },
  {
    title: 'Software Engineering',
    image: '/assets/SoftwareEngineering.png',
    description: 'Innovative software engineering solutions tailored to automate workflows and empower businesses with scalable, future-proof technology.',
    useCase: 'A logistics company required software to automate delivery scheduling. We built a custom solution that optimized their workflow and saved costs.'
  },
  {
    title: 'Project Management',
    image: '/assets/ProjectManagement.png',
    description: 'Expert project management services ensuring smooth execution, timely delivery, and goal alignment with client objectives for optimal results.',
    useCase: 'An event organizer struggled with deadlines. Our project management ensured everything ran smoothly, keeping tasks on schedule and stress-free.'
  },
  {
    title: 'Web Valet Services',
    image: '/assets/WebValet.png',
    description: 'Comprehensive web valet services, ensuring round-the-clock site optimization, monitoring, and maintenance for seamless online operations.',
    useCase: 'A retail store faced slow page speeds. Our valet services monitored and optimized their site 24/7, ensuring peak performance during high-traffic periods.'
  },
  {
    title: 'Cyber Security',
    image: '/assets/CyberSecurity.png',
    description: 'Advanced cyber security solutions that safeguard sensitive data, prevent threats, and ensure compliance with industry standards.',
    useCase: 'A startup experienced phishing attempts. We implemented robust security protocols to protect sensitive data and prevent breaches.'
  },
  {
    title: 'Data Management',
    image: '/assets/DataManagement.png',
    description: 'Secure data management services designed to streamline access, storage, and analytics, ensuring the integrity and availability of your data.',
    useCase: 'A healthcare provider needed help managing patient records. Our data management solutions ensured secure, compliant, and easy access to records.'
  },
  {
    title: 'E-Commerce Solutions',
    image: '../assets/ECommerce.png',
    description: 'End-to-end e-commerce solutions that enhance online stores with modern UX design, secure payments, and seamless product management.',
    useCase: 'A small business shifted online during the holidays. Our e-commerce platform enabled them to scale rapidly and maximize seasonal sales.'
  },
  {
    title: 'Mobile App Development',
    image: '/assets/MobileApp.png',
    description: 'Crafting responsive mobile apps with intuitive interfaces to provide top-tier experiences for both iOS and Android platforms.',
    useCase: 'A fitness coach wanted to offer virtual classes. We developed a mobile app that connected them with clients anytime, anywhere.'
  },
  {
    title: 'AI and Machine Learning',
    image: '/assets/AI.png',
    description: 'Empowering businesses with cutting-edge AI and machine learning technologies that drive intelligent automation and innovation.',
    useCase: 'An online retailer needed personalized recommendations. Our AI solution increased customer engagement and boosted revenue.'
  }
];



const Services = () => {
 
  

  

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{  padding: '50px 0', color: '#fff' }}
    >
      <Container maxWidth="lg">
        <Parallax speed={10} >        
          <Typography 
          id="#services" 
          variant="h2" 
          align="center" 
          gutterBottom
          sx={{ marginBottom: '40px' }}
        >
          Our Services
        </Typography>
        </Parallax>

        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} key={index}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <Box
                 
                  className='box' // Using the box class from CSS
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '30px',
                    borderRadius: '10px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                    height: 'auto',
                    transition: 'transform 0.3s ease-in-out',
                    justifyContent: 'center',
                    alignContent: 'center',
                    position: 'relative',
                    gap: 3,
                  }}
                >
                  <Box
                    component="img"
                    src={service.image}
                    alt={service.title}
                    sx={{
                      width: {xs: '200px', md: '400px'},
                      height: {xs: '200px', md: '400px'},
                      margin: '0 auto',
                      filter: 'grayscale(100%)', // Apply grayscale filter
                      transition: 'filter 0.3s ease-in-out',
                      '&:hover': {
                        filter: 'grayscale(0%)', // Remove grayscale on hover
                      },
                    }}
                  />
                  <Typography variant="h4" gutterBottom sx={{ color: '#fff' }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#ccc' }}>
                    {service.description}
                  </Typography>
                  <Typography variant="h6" sx={{fontWeight: 600 ,color: '#fff', fontStyle: 'italic'}}>
                    Use case: {service.useCase}
                  </Typography>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </motion.div>
  );
};

export default Services;
