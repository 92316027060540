import React, { useRef } from 'react';
import { Box, Grid } from '@mui/material';
import MediaCard from '../components/MediaCard';
import MediaCardParallaxScene from '../components/MediaCardParallaxScene';
import PsychedelicStarScene from '../components/PsychedelicStarScene';
import HeroSection from '../components/HeroSection';
import { Helmet } from 'react-helmet-async';

const videoData = [
  { title: "ASAP ROCKY - Taylor Swift", description: "", videoUrl: "https://www.youtube.com/embed/5URefVYaJrA?si=nhX1PTzd_eLGpaYY" },
  { title: "JPEGMAFIA x Denzel Curry - JPEGULTRA!", description: "", videoUrl: "https://www.youtube.com/embed/zRnnuImT930?si=MJvPDmMVJg6Boo7l" },
  { title: "Key! - Too Much", description: "Vibes with Key!'s unique sound.", videoUrl: "https://www.youtube.com/embed/y3Og_6XUML4?si=5obZcPD4KKVqB2hA" },
  { title: "Kid Cudi, Chip Tha Ripper - DONT WORRY", description: "", videoUrl: "https://www.youtube.com/embed/NQHB0o9kqVQ?si=VLO96jsj4xHDQeUn" },
  { title: "Mr.Kitty - After Dark", description: "", videoUrl: "https://www.youtube.com/embed/sVx1mJDeUjY?si=Na5V_804pRqfy3xM" },
  { title: "FC Kahuna - Hayling", description: "", videoUrl: "https://www.youtube.com/embed/G1QkpGMN_C4?si=flCG8dNUvj9b6omY" },
  { title: "mija - techno & hard groove mix | electric cleaners", description: "", videoUrl: "https://www.youtube.com/embed/Y6_R8dNlCFM?si=y6u5hh7URyi1yMhs" },
];

const MediaPage = () => {
  // Store refs to all video elements to control playback
  const videoRefs = useRef([]);

  const handlePlay = (index) => {
    // Pause all videos except the currently playing one
    videoRefs.current.forEach((video, i) => {
      if (video && i !== index) {
        video.pause();
      }
    });
  };

  return (
    <>
    <Helmet>
        <title>Media Gallery | Neal Frazier Tech</title>
        <meta
          name="description"
          content="Explore a curated selection of videos, music, and tech content in the Neal Frazier Tech media gallery. Immerse yourself in a unique blend of visual and musical experiences."
        />
        <meta
          name="keywords"
          content="Neal Frazier Tech, Media Gallery, Videos, Music, Tech Content, ASAP Rocky, Kid Cudi, JPEGMAFIA, Virginia Beach, technology media"
        />
        <meta property="og:title" content="Media Gallery | Neal Frazier Tech" />
        <meta
          property="og:description"
          content="Dive into Neal Frazier Tech's exclusive media page, featuring a psychedelic gallery of videos and music for tech enthusiasts."
        />
        <meta property="og:image" content="/NealFrazierTech.png" />
        <meta property="og:url" content="https://nealfrazier.tech/media" />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Media Gallery | Neal Frazier Tech" />
        <meta property="twitter:description" content="Discover a unique media experience with videos, music, and tech content curated by Neal Frazier Tech." />
        <meta property="twitter:image" content="/NealFrazierTech.png" />
      </Helmet>
    <HeroSection />
    <MediaCardParallaxScene />
        <Box
      sx={{
        backgroundColor: '#000000',
        minHeight: '100vh',
        padding: '100px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'radial-gradient(circle, #00ffea, #121212)',
          opacity: 0.1,
          zIndex: -1,
        },
      }}
    >
     
      {/* Glitch Background Effect */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'url(/path-to-glitch-background.png)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          opacity: 0.07,
          zIndex: -2,
          animation: 'glitchBg 3s infinite linear',
          '@keyframes glitchBg': {
            '0%': { transform: 'translate(0px, 0px)' },
            '25%': { transform: 'translate(2px, -2px)' },
            '50%': { transform: 'translate(-2px, 2px)' },
            '75%': { transform: 'translate(2px, 2px)' },
            '100%': { transform: 'translate(0px, 0px)' },
          },
        }}
      />

      {/* Media Cards */}
      <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ maxWidth: 600 }}>
        {videoData.map((item, index) => (
          <Grid item key={index} xs={12}>
            <MediaCard
              title={item.title}
              description={item.description}
              videoUrl={item.videoUrl}
              onPlay={() => handlePlay(index)}
              videoRef={(el) => (videoRefs.current[index] = el)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
    <PsychedelicStarScene />
    </>

  );
};

export default MediaPage;
