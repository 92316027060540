import React from 'react';
import { Box, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { Parallax} from 'react-scroll-parallax';

const YouTubeEmbed = ({ videoUrl }) => {
  
  return (
    <Parallax rtranslateX={['-400px', '0px']}
    scale={[0.45, 1.5]}
    easing="easeInQuad" >
    <Container maxWidth="md" sx={{ py: 4 }}>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8 }}
      >
        <Box
          sx={{
            position: 'relative',
            paddingBottom: '56.25%', // Maintain 16:9 aspect ratio
            height: 0,
            overflow: 'hidden',
            borderRadius: 2,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', // Shadow for the dark theme
            backgroundColor: '#121212', // Dark background
          }}
        >
          <iframe
            src={videoUrl}
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
            }}
          />
        </Box>
      </motion.div>
    </Container>
    </Parallax>
  );
};

export default YouTubeEmbed;
