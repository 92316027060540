import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Parallax } from 'react-scroll-parallax';

// Stoic and Tech Quotes
const quotes = [
  // Stoic Quotes
  "The happiness of your life depends upon the quality of your thoughts. – Marcus Aurelius",
  "He who fears death will never do anything worth of a man who is alive. – Seneca",
  "If it is not right, do not do it; if it is not true, do not say it. – Marcus Aurelius",
  "Luck is what happens when preparation meets opportunity. – Seneca",
  
  // Tech Leaders Quotes
  "Stay hungry, stay foolish. – Steve Jobs",
  "The Internet is the first thing that humanity has built that humanity doesn't understand, the largest experiment in anarchy that we have ever had. – Eric Schmidt",
  "Hackers are breaking the systems for profit. Before, it was about intellectual curiosity and pursuit of knowledge and thrill, and now hacking is big business. – Kevin Mitnick",
  "The biggest risk is not taking any risk... In a world that is changing really quickly, the only strategy that is guaranteed to fail is not taking risks. – Mark Zuckerberg",
  "I think one of the most important skills of the future will be learning how to figure things out on your own. – Sam Altman",
];

// Ticker component
const FancyTicker = () => {
  const [currentQuote, setCurrentQuote] = useState(0);

  // Change quote every 7 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuote((prevQuote) => (prevQuote + 1) % quotes.length);
    }, 7000); // Adjust timing as needed

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{
        backgroundColor: '#1a1a1a',
        color: '#fff',
        padding: '10px 20px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
      }}
    >
      <Parallax speed={-100}>
      <Box
        sx={{
          display: 'flex',
          height: '100px',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          // Responsive font size and padding adjustments
          '@media (max-width: 768px)': {
            padding: '10px',
          },
          '@media (max-width: 600px)': {
            padding: '5px',
          },
        }}
      >
        <motion.div
          key={currentQuote}
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ duration: 1 }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              whiteSpace: 'normal', // Allow text wrapping
              textAlign: 'center',
              // Responsive font size
              '@media (max-width: 768px)': {
                fontSize: '16px',
              },
              '@media (max-width: 600px)': {
                fontSize: '14px',
              },
            }}
          >
            {quotes[currentQuote]}
          </Typography>
        </motion.div>
      </Box>
      </Parallax>
    </motion.div>
  );
};

export default FancyTicker;
