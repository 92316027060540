import React, { useRef, useEffect } from 'react';
import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {  scroller } from 'react-scroll'; // Import react-scroll
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import BuildIcon from '@mui/icons-material/Build';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ArticleIcon from '@mui/icons-material/Article';

// Define glitch animation variants
const menuVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 200,
      damping: 20,
    },
  },
  closed: {
    opacity: 0,
    y: -2000,
    transition: {
      duration: 0.2,
    },
  },
};

const StyledMenu = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  top: '64px', // Adjust based on toolbar height
  right: 0,
  left: 0,
  width: '100%',
  color: '#fff', // Neon cyan color
  boxShadow: '0px 0px 5px rgba(255, 255, 255, 0.5)',
  zIndex: 1300,
  fontFamily: '"Orbitron", monospace',
  background: 'rgba(0, 0, 0, 0.9)',
  border: '1px dashed white',
  borderRadius: '50px',
  paddingTop: '30px',
  paddingBottom: '30px',
}));

const MenuItems = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  width: '90%',
  margin: 'auto',
  padding: '16px 34px',
  textAlign: 'center',
  backgroundColor: 'transparent',
  color: '#fff',
  fontFamily: '"Orbitron", monospace',
  fontWeight: 'bold',
  fontSize: '1.1rem',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  position: 'relative',
  overflow: 'hidden',
  transition: 'color 0.3s',
  
  '&:hover': {
    color: '#grey',
    backgroundColor: 'rgba(255, 255, 239, 0.1)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '-75%',
    top: 0,
    width: '50%',
    height: '100%',
    background:
      'linear-gradient(90deg, transparent, rgba(255, 255, 231, 0.2), transparent)',
    transform: 'skewX(-45deg)',
  },
  '&:hover::before': {
    animation: 'glow 0.5s forwards',
  },
  '@keyframes glow': {
    to: {
      left: '125%',
    },
  },
}));

const IconWrapper = styled('span')({
  marginRight: '10px',
  display: 'inline-flex',
  verticalAlign: 'middle',
});

const DropdownMenu = ({ open, onClose }) => {
  const menuRef = useRef(null);
  const location = useLocation(); // Get current route location
  const navigate = useNavigate();  // Function to navigate between pages

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);

  // Function to handle scrolling to the #contact section
  const handleContactClick = () => {
    if (location.pathname === '/') {
      // If already on the homepage, scroll to the 'contact' element
      scroller.scrollTo('contact', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    } else {
      // If on another page, navigate to the homepage and scroll to 'contact'
      navigate('/');
      setTimeout(() => {
        scroller.scrollTo('contact', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }, 100);  // Delay to ensure page is loaded before scrolling
    }
    onClose();  // Close the menu
  };

  return (
    <StyledMenu
      ref={menuRef}
      initial="closed"
      animate={open ? 'open' : 'closed'}
      variants={menuVariants}
    >
      <MenuItems>
        <Link to="/" onClick={onClose} style={{ textDecoration: 'none', width: '100%' }}>
          <MenuItemStyled>
            <IconWrapper>
              <HomeIcon sx={{ color: 'inherit' }} />
            </IconWrapper>
            Home
          </MenuItemStyled>
        </Link>
        <Link
          to="/about"
          onClick={onClose}
          style={{ textDecoration: 'none', width: '100%' }}
        >
          <MenuItemStyled>
            <IconWrapper>
              <InfoIcon sx={{ color: 'inherit' }} />
            </IconWrapper>
            About
          </MenuItemStyled>
        </Link>
        <Link
          to="/services"
          onClick={onClose}
          style={{ textDecoration: 'none', width: '100%' }}
        >
          <MenuItemStyled>
            <IconWrapper>
              <BuildIcon sx={{ color: 'inherit' }} />
            </IconWrapper>
            Services
          </MenuItemStyled>
        </Link>
        {/* Handle contact scroll behavior */}
        <MenuItemStyled onClick={handleContactClick}>
          <IconWrapper>
            <ContactMailIcon sx={{ color: 'inherit' }} />
          </IconWrapper>
          Contact
        </MenuItemStyled>
        <Link
          to="/nftblogs"
          onClick={onClose}
          style={{ textDecoration: 'none', width: '100%' }}
        >
          <MenuItemStyled>
            <IconWrapper>
              <ArticleIcon sx={{ color: 'inherit' }} />
            </IconWrapper>
            Blogs
          </MenuItemStyled>
        </Link>
        <Link
          to="/media"
          onClick={onClose}
          style={{ textDecoration: 'none', width: '100%' }}
        >
          <MenuItemStyled>
            <IconWrapper>
              <ArticleIcon sx={{ color: 'inherit' }} />
            </IconWrapper>
            Media
          </MenuItemStyled>
        </Link>
      </MenuItems>
    </StyledMenu>
  );
};

export default DropdownMenu;
