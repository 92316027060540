import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Box, Typography } from '@mui/material';

// Hero Section Component
const HeroSection = () => {
  // Background gradient animation
  const backgroundSpring = useSpring({
    from: { backgroundPosition: '0% 50%' },
    to: async (next) => {
      while (1) {
        await next({ backgroundPosition: '100% 50%' });
        await next({ backgroundPosition: '0% 50%' });
      }
    },
    config: { duration: 5000 },
  });

  return (
    <animated.div
      style={{
        ...backgroundSpring,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(90deg, #ff00ff, #00ffff, #ffdd00, #00ffcc)',
        backgroundSize: '300% 300%',
        overflow: 'hidden',
      }}
    >
      <Box textAlign="center">
        <WavyText text="Welcome to Neal Frazier Tech's Media Page" />
      </Box>
    </animated.div>
  );
};

// Wavy Text Component
const WavyText = ({ text }) => {
  return (
    <Typography
      variant="h2"
      sx={{
        color: '#fff',
        fontWeight: 'bold',
        letterSpacing: '0.1em',
        textShadow: '0 0 10px rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 255, 255, 0.3)',
        fontSize: {lg: '3rem', xs: '1rem'},
        position: 'relative',
      }}
    >
      {text.split('').map((char, index) => (
        <WavyChar key={index} char={char} index={index} />
      ))}
    </Typography>
  );
};

// Wavy Character Component
const WavyChar = ({ char, index }) => {
  const wavySpring = useSpring({
    from: { transform: 'translateY(0px)' },
    to: async (next) => {
      while (1) {
        await next({ transform: 'translateY(-5px)' });
        await next({ transform: 'translateY(5px)' });
        await next({ transform: 'translateY(0px)' });
      }
    },
    config: { duration: 500 + index * 50 },
  });

  return (
    <animated.span style={{ ...wavySpring, display: 'inline-block' }}>{char === ' ' ? '\u00A0' : char}</animated.span>
  );
};

export default HeroSection;
