import React from 'react';
import { Card, CardContent, Typography, Avatar, Button, Box } from '@mui/material';
import PodcastComponent from '../components/PodcastComponent';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <ParallaxProvider>
      <Helmet>
        <title>About Neal Frazier | Web Developer & Linux Enthusiast</title>
        <meta name="description" content="Learn more about Neal Frazier, a passionate web developer and Linux enthusiast based in Virginia Beach, Virginia. Explore his expertise in React, Supabase, MongoDB, and more." />
        <meta name="keywords" content="Neal Frazier, Web Developer, Linux, React, Supabase, MongoDB, Heroku, Skateboarder, Music Lover" />
        <meta property="og:title" content="About Neal Frazier | Web Developer & Linux Enthusiast" />
        <meta property="og:description" content="Discover more about Neal Frazier, his journey in web development, and his passion for Linux, skateboarding, and music." />
        <meta property="og:url" content="https://nealfrazier.tech/about" />
        <meta property="og:image" content="https://nealfrazier.tech/images/newlogo.png" /> {/* Replace with your actual image */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Neal Frazier | Web Developer & Linux Enthusiast" />
        <meta name="twitter:description" content="Learn more about Neal Frazier, a passionate web developer and Linux enthusiast." />
        <meta name="twitter:image" content="https://nealfrazier.tech/images/newlogo.png" /> {/* Replace with your actual image */}
      </Helmet>
      <Box sx={{ padding: '2rem', paddingTop: 20, maxWidth: '900px', margin: '0 auto', color: 'white', minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        
        {/* Profile Section */}
        <Box sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Avatar
            alt="Neal Frazier"
            src="/images/Neal-Frazier-Tech.jpeg" // Add the path to your profile picture here
            sx={{ 
              width: 150, 
              height: 150, 
              margin: '0 auto', 
              filter: 'grayscale(50%) blur(2px)', // Apply grayscale and blur filter
              transition: 'filter 0.3s ease-in-out', 
              '&:hover': { 
                filter: 'grayscale(0%) blur(0)', // Remove filters on hover
              } 
            }}
          />
          <Typography 
            variant="h4" 
            component="h1" 
            sx={{ 
              marginTop: '1rem', 
              textShadow: '2px 2px 5px rgba(0,0,0,0.7)' // Add subtle text-shadow 
            }}>
            Neal Frazier
          </Typography>
          <Typography 
            variant="h6" 
            component="h2" 
            sx={{ 
              marginTop: '0.5rem', 
              color: '#bdbdbd', 
              fontSize: {xs: '1rem', md: '1.5rem'},
              transition: 'color 0.3s ease-in-out',
              '&:hover': { 
                color: '#fff', // Change text color on hover
                
              },
            }}>
            Web Developer | Linux Enthusiast | Skater | Music Lover
          </Typography>
        </Box>

        {/* About Me Section */}
        <Typography 
          variant="body1" 
          sx={{ 
            marginBottom: '2rem', 
            lineHeight: '1.6',
            filter: 'brightness(90%)', // Slight brightness reduction
            transition: 'filter 0.3s ease-in-out',
            '&:hover': {
              filter: 'brightness(110%)', // Increase brightness on hover
            }
          }}>
          Hi, I'm Neal Frazier, a passionate web developer and Linux enthusiast currently based in Virginia Beach, Virginia. 
          I specialize in creating fast, scalable websites with technologies such as React, Supabase, MongoDB, and Heroku. 
          I'm deeply focused on mastering Linux fundamentals and currently working on a "30 Days of Linux Fundamentals" course 
          using C as the language to run the course. Outside of development, I'm a skateboarder, fruitarian, and avid music lover, 
          continuously exploring new ways to grow both personally and professionally.
        </Typography>

        {/* Podcast Component */}
        <PodcastComponent />

        {/* Contact Info Card */}
        <Card sx={{ backgroundColor: '#2e2e2e', marginTop: '2rem', filter: 'drop-shadow(0 0 10px #000)', transition: 'filter 0.3s ease-in-out', '&:hover': { filter: 'drop-shadow(0 0 30px #000)' } }}>
          <CardContent>
            <Typography 
              variant="h5" 
              component="h2" 
              sx={{ color: 'white', textShadow: '1px 1px 4px rgba(255, 255, 255, 0.3)' }}
            >
              Contact Information
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ marginTop: '1rem', color: '#bdbdbd' }}
            >
              <strong>Email:</strong> business@nealfrazier.tech
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ marginTop: '0.5rem', color: '#bdbdbd' }}
            >
              <strong>Location:</strong> Virginia Beach, VA
            </Typography>

            {/* Button with Glow Effect */}
            <Box sx={{ marginTop: '1rem' }}>
              <Button 
                variant="contained" 
                href="https://github.com/1nc0gn30/30-days-of-Linux-Fundamentals" 
                target="_blank" 
                rel="noopener noreferrer" 
                sx={{ 
                  backgroundColor: 'white', 
                  marginRight: '1rem', 
                  color: 'black', 
                  fontFamily: 'Crypto-Crash-Font', 
                  fontSize: '1.5rem', 
                  margin: '0 auto',
                  transition: 'box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0px 0px 50px rgba(255, 255, 255, 0.8)', // Glow effect on hover
                    backgroundColor: 'black',
                    color: 'white',
                  } 
                }}
              >
                My GitHub
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ParallaxProvider>
  );
};

export default About;
