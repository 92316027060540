import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // Sets the overall mode to dark
    background: {
      default: '#121212', // Body background color
    },
    text: {
      primary: '#ffffff', // Default text color
    },
  },
  typography: {
    fontFamily: 'LCD-Font, Arial, sans-serif', // Default font family
    h1: {
      fontFamily: 'Crypto-Crash-Font', // Custom font for h1
      fontSize: '4rem',
      '@media (max-width:768px)': {
        fontSize: '3rem',
      },
    },
    h2: {
      fontFamily: 'Orbitron', // Custom font for h2
    },
    h3: {
      fontFamily: 'AntiMatrix-Font', // Custom font for h3
    },
    h4: {
      fontFamily: 'AntiMatrix-Font', // Custom font for h4
    },
    h5: {
      fontFamily: 'AntiMatrix-Font', // Custom font for h5
    },
    h6: {
      fontFamily: 'LCD-Font', // Custom font for h6
    },
    body1: {
      fontFamily: 'LCD-Font', // Default font for body text
    },
  },
  components: {
    MuiCssBaseline: {
          body: {
            margin: 0,
            backgroundColor: '#121212',
            color: '#ffffff',
            overflowX: 'hidden',
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
          },
         
          code: {
            fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`, // Default font for code blocks
          },
        },
      },
    },

);

export default theme;
